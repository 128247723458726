// @flow

// Libraries
import * as React from 'react';
import { Link } from 'react-router-dom';

// Components
import {
  Button,
  Checkbox,
  Container,
  Grid,
  InputAdornment,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField
} from '@material-ui/core';
import Page from '@components/Page';
import { StyledFormControlLabel } from '../../Main/Components';
import Modal from './Modal';

// API
import { editSMI, fetchSMI, uploadSourceRanks } from '../../../api/SMIApi';

// Constants
import { server } from '@constants/servers';

// Assets
import arrowLeft from '@assets/arrowLeft.svg';
import { ReactComponent as TriangleIcon } from '@assets/triangle.svg';
import { ReactComponent as EditIcon } from '@assets/edit.svg';
import SearchIcon from '@material-ui/icons/Search';
import stub_smi from '@assets/stub_smi.svg';

// Styles
import styles from './styles.sass';
import { ArrowUpward } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { getBranchByValue } from '../../../methods/smiMethods';
import QueryWrapper from "../../../api/QueryWrapper";
import {getToken} from "../../../api/articleApi";
import fileDownload from "js-file-download";

const ITEMS_ON_PAGE = 40;

const HandbookSMI = () => {
  const { monitoring_regions: regions } = useSelector(store => store.monitoring_constants);

  const [smi, setSMI] = React.useState([]);
  const [countOfAllSMI, setCountOfAllSMI] = React.useState(0);
  const [foundName, setFoundName] = React.useState(null);
  const [sortTypes, setSortTypes] = React.useState(regions.map(type => ({ ...type, status: true })));
  const [sortTypesAnchors, setSortTypesAnchors] = React.useState({
    branch: null
  });
  const [dense] = React.useState(false);
  const [showModal, setShowModal] = React.useState(null);
  const [modalData, setModalData] = React.useState({
    content: {},
    mode: null
  });
  const [page, setPage] = React.useState(1);
  const [event, setEvent] = React.useState({
    open: false,
    message: null,
    status: null
  });
  const [loading, setLoading] = React.useState(true);

  const fileRef = React.useRef(null);

  const currentPage = page;
  const lastPage = countOfAllSMI / ITEMS_ON_PAGE < 1 ? 1 : Math.ceil(countOfAllSMI / ITEMS_ON_PAGE);

  const getSMI = async () => {
    setLoading(true);
    await fetchSMI(
      {
        page,
        foundName,
        branches: sortTypes.filter(({ status }) => status).map(({ name }) => name)
      },
      res => {
        setCountOfAllSMI(res.count);
        setSMI(prev => [...prev, ...res.data])
        setLoading(false);
      }
    );
  };

  const handleUpdate = () => {
    setPage(1);
    setSMI([]);
    getSMI();
  }

  console.log(smi);

  React.useEffect(() => {
    handleUpdate();
  }, [foundName]);

  React.useLayoutEffect(() => {
    if (smi.length < countOfAllSMI && (smi.length / ITEMS_ON_PAGE) < page && page !== 0)
      getSMI();
  }, [page]);

  const headCells = [
    { id: 'img', disablePadding: false, label: '', width: 50},
    { id: 'name', disablePadding: false, label: 'Название СМИ', width: 250 },
    { id: 'top', disablePadding: false, label: 'Топ СМИ', width: 120, center: true },
    { id: 'pr_value', disablePadding: false, label: 'PR Value', width: 200 },
    { id: 'level_id', disablePadding: false, label: 'Тип', width: 300 },
    { id: 'branch', disablePadding: false, label: 'Макрорегион', width: 200, modal: true },
    { id: 'geography', disablePadding: false, label: 'География', width: 200, modal: true },
    { id: 'coverage', disablePadding: false, label: 'Аудитория', width: 200},
    { id: 'coverage_updated_at', disablePadding: false, label: 'Дата последнего обновления', width: 100 },
    { id: 'category_name', disablePadding: false, label: 'Категория', width: 100 },
    { id: 'edit', disablePadding: false, label: '', width: 100 },
  ];

  const handleSave = () => {
    setShowModal(false);
    const editedData = { ...modalData };
    delete editedData.content.branch;

    switch (modalData.mode) {
      case 'EDIT':
        editSMI(editedData.content, (res) => {
          setSMI(prev => {
            const foundIndex = prev.findIndex(({ id }) => id === res.id);
            const editedSMI = [...prev];
            editedSMI[foundIndex] =  { ...res };
            return editedSMI;
          })
        });
        return;
      default:
        return;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [unk, sortAnchor] = Object.entries(sortTypesAnchors).find(([_, value]) => value !== null) || [];

  const tableData = React.useMemo(() => {
    return smi.slice((page - 1) * ITEMS_ON_PAGE, (page - 1) * ITEMS_ON_PAGE + ITEMS_ON_PAGE)
  }, [modalData, smi, page]);

  const handleOpenModal = (property) => (event) => {
    const { currentTarget } = event;
    setSortTypesAnchors(prev => ({ ...prev, [property]: currentTarget }))
  };

  return (
    <Page loading={loading} event={event}>
      <Grid
        container
        direction="column"
      >
        <Container>
          <Popover
            open={sortAnchor}
            anchorEl={sortAnchor}
            onClose={() => {
              setSortTypesAnchors({ branch: null });
              handleUpdate();
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{marginTop: 11}}
          >
            {sortAnchor && (
              <div className={styles.PopoverSort}>
                <div className={styles.PopoverSort__Title}>
                  Выберите фильтры...
                </div>
                <div className={styles.PopoverSort__Content}>
                  {sortTypes.map(type => (
                    <div>
                      <StyledFormControlLabel
                        key={type.id}
                        control={<Checkbox checked={type.status} />}
                        label={type.name}
                        onChange={({ target: { checked } }) => setSortTypes(prev => {
                          const updatedTypes = [...prev];
                          const foundIndex = updatedTypes.findIndex(({ id }) => id === type.id);
                          updatedTypes[foundIndex] = {
                            ...updatedTypes[foundIndex],
                            status: checked
                          }
                          return updatedTypes;
                        })}
                      />
                    </div>
                  ))}
                </div>
                <Button
                  variant='outlined'
                  onClick={() => setSortTypes(prev => prev.map(type => ({ ...type, status: true })))}
                  style={{width: '100%'}}
                >
                  Выбрать все
                </Button>
              </div>
            )}
          </Popover>
          <Grid
            lg={12}
            xl={12}
            container
            direction="row"
            className={styles.SearchBlock}
          >
            <Link to='/handbooks'>
              <div className={styles.Back}>
                <img alt="" src={arrowLeft} />
                <span className={styles.Back__text}>Назад</span>
              </div>
            </Link>
          </Grid>
          <Grid
            lg={12}
            xl={12}
            container
            direction="row"
            alignItems="center"
            className={styles.SearchBlock}
          >
            <Grid item xs={3}>
              <div className={styles.MainTitle}>Справочник СМИ</div>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={`${styles.Button} ${styles.Button_inverted}`}
                onClick={async () => {
                  await fetch(`${server}/api/v1/monitoring/sources/export`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'blob',
                      'Authorization': `Bearer ${getToken()}`
                    },
                  })
                  .then(res => res.blob())
                  .then(res => {
                    let a = document.createElement("a");
                    a.href = URL.createObjectURL(res);
                    a.download = `file.xlsx`;
                    a.click();
                  });
                }}
              >
                <ArrowUpward />
              </Button>
              <Button
                className={`${styles.Button} ${styles.Button_purple}`}
                onClick={() => fileRef.current.click()}
              >
                <ArrowUpward />
              </Button>
              <input
                ref={fileRef}
                name="myFile"
                type="file"
                onChange={async (e) => {
                  setLoading(true);
                  await uploadSourceRanks(
                    e.target.files[0],
                    () => {
                      getSMI();
                      setEvent({ open: true, message: 'Файл успешно загружен!', status: 'success' });
                    },
                    () => {
                      setEvent({ open: true, message: 'Не удалось загрузить файл!', status: 'failed' });
                    }
                  );
                  setLoading(false);
                }}
                style={{ display: "none" }}
              />
              <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                modalData={modalData}
                setModalData={setModalData}
                handleSave={handleSave}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                placeholder="Поиск"
                style={{
                  width: '100%',
                }}
                variant="outlined"
                icon
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setFoundName(e.target.value)
                  }
                }}
              />
            </Grid>
          </Grid>
          <Paper className={styles.Paper}>
            {smi.length > 0 && (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0'}}>
                <Button
                  onClick={() => setPage(prev => prev - 1)}
                  disabled={page === 1}
                >
                  {'<'}
                </Button>
                <div style={{margin: '0 10px'}}>
                  Страница
                  {' '}
                  {currentPage}
                  {' '}
                  из
                  {' '}
                  {lastPage || '...'}
                </div>
                <Button
                  onClick={() => setPage(prev => prev + 1)}
                  disabled={currentPage === lastPage}
                >
                  {'>'}
                </Button>
              </div>
            )}
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
                theme='black'
                fixedHeader={false}
                style={{ width: "auto", tableLayout: "auto" }}
              >
                <TableHead>
                  <TableRow>
                    {headCells.map(headCell => (
                      <TableCell
                        key={headCell.id}
                        align='left'
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        className={styles.Title}
                        style={{
                          width: headCell.width,
                          minWidth: 'auto !important',
                        }}
                      >
                        {headCell?.modal
                          ? (
                            <TableSortLabel
                              onClick={handleOpenModal(headCell.id)}
                              IconComponent={() => <TriangleIcon className={styles.SortIcon} />}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          )
                          : headCell.label
                        }
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.length > 0 && tableData.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          <img alt="" src={row.icon_url ? row.icon_url : stub_smi} className={styles.IconSMI} />
                        </TableCell>
                        <TableCell width="300px" component="th" id={labelId} scope="row" padding="10px 0 0 0">
                          {row.name}
                        </TableCell>
                        <TableCell width="100px" padding="checkbox" style={{margin: '0 auto'}}>
                          <Checkbox
                            checked={row.top}
                            onClick={() => {
                              setSMI(prev => prev.map(prevRow =>
                                prevRow.name === row.name
                                  ? {...prevRow, top: !prevRow.top}
                                  : prevRow
                              ))
                            }}
                            inputProps={{ 'aria-labelledby': labelId }}
                            disabled
                          />
                        </TableCell>
                        <TableCell width="200px" align="left">
                          <div className={styles.Edited}>
                            <span>
                              {row.pr_value}
                            </span>
                            {row.pr_value_date && (
                              <span className={styles.Edited__Content}>
                                <EditIcon fill='#9E9E9E' />
                                <span>
                                  Изменено
                                  {` ${row.pr_value_date}`}
                                </span>
                              </span>
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="left">{getBranchByValue(row?.level_id)}</TableCell>
                        <TableCell align="left">{row?.branch?.name}</TableCell>
                        <TableCell align="left">{row?.branch?.region_name}</TableCell>
                        <TableCell align="left">{row?.coverage ? row?.coverage : 0 }</TableCell>
                        <TableCell align="left">{row?.coverage_updated_at ? row?.coverage_updated_at : 0 }</TableCell>
                        <TableCell align="left">{row?.category_name ? row?.category_name : 0 }</TableCell>
                        <TableCell align="right">
                          <div
                            className={styles.Edit}
                            onClick={() => {
                              setShowModal(true);
                              setModalData({ content: row, mode: 'EDIT' });
                            }}
                          >
                            <EditIcon fill='#00B956' />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Container>
      </Grid>
    </Page>
  )
};

export default HandbookSMI;
