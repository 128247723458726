export const roleConverter = (role) => {
  switch (role) {
    case 'user':
      return 'Пользователь';
    case 'editor':
      return 'Редактор';
    case 'analyst':
      return 'Аналитик';
    case 'admin':
      return 'Администратор';
    case 'pr_manager':
      return 'PR менеджер';
    default:
      return 'Пользователь';
  }
};
