// @flow
import React from 'react';
import { Button } from '@mui/material';
import { server } from '@constants/servers';
import { getToken } from '../../api/articleApi';

const date = new Date();

// https://app.weeek.net/ws/435314/task/8693
function ExportReportMonitoring() {
  const exportMonitoring = async () => {
    const res = await fetch(`${server}/api/v1/monitoring/newsletters/export`, {
      method: 'GET',
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${getToken()}`
      }
    });

    const blob = await res.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `stories_${date.getDate()}_${date.getMonth() +
      1}_${date.getFullYear()}.xlsx`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <Button
      onClick={exportMonitoring}
      style={{
        background: '#00B956',
        color: '#ffffff'
      }}
    >
      Экспорт сюжетов
    </Button>
  );
}

export default ExportReportMonitoring;
