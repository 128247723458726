// @flow

// Libraries
import * as React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import YandexShare from 'react-yandex-share';
import classNames from 'classnames';
import MetaTags from 'react-meta-tags';

// Components
import { Container, Grid } from '@material-ui/core';
import Page from '@components/Page';
import { CAPTION, H4, H6, SUBTITLE1 } from '@components/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import BackButton from '../../components/BackButton';

// Api
import {
  bookmarkingArticle,
  getArticle,
  getChildArticles,
  removeFromBookmarks
} from '../../api/articleApi';

// Assets
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import stub_smi from '@assets/stub_smi.svg';

// Styles
import styles from './styles.sass';

// Types
import type ArticlePageTypes from '@types/pageTypes';
import CopyLinkButton from '../../components/CopyLinkButton';

export const getFolder = (mainArticle, articles) => {
  if (!articles) return [];

  return articles.filter(
    ({
      monitoring_type,
      monitoring_region,
      monitoring_section,
      monitoring_date
    }) =>
      mainArticle.monitoring_type === monitoring_type &&
      mainArticle.monitoring_region === monitoring_region &&
      mainArticle.monitoring_section === monitoring_section &&
      moment(mainArticle.monitoring_date).format('YYYY-MM-DD') ===
        moment(monitoring_date).format('YYYY-MM-DD')
  );
};

const Article = ({
  match: {
    params: { id }
  }
}: ArticlePageTypes) => {
  const role = useSelector(({ user }) => user.role);
  const [article, setArticle] = React.useState(null);
  const [event, setEvent] = React.useState({
    open: false,
    message: '',
    status: 'success'
  });
  const [countOfChildren, setCountOfChildren] = React.useState(null);

  const screenIsTablet = useMediaQuery('(max-width:991px)');

  React.useLayoutEffect(() => {
    getArticle(id, res => setArticle(res));
  }, [id]);

  React.useEffect(() => {
    if (article) {
      getChildArticles(
        article.post_id || article.id,
        res =>
          res &&
          res.length > 1 &&
          setCountOfChildren(getFolder(article, res).length)
      );
    }
  }, [article]);

  React.useEffect(() => {
    return () => {
      localStorage.setItem('fromArticles', false);
      localStorage.setItem('fromBookmarks', false);
      localStorage.setItem('fromGroup', false);
    };
  }, []);

  const toBookmarkHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    setArticle(prev => ({ ...prev, is_favorite: !prev.is_favorite }));

    bookmarkingArticle(
      id,
      () => {
        setEvent({
          open: true,
          message: 'Новость добавлена в закладки',
          status: 'success'
        });
      },
      () => {
        setEvent({
          open: true,
          message: 'Ошибка при добавлении новости в закладки',
          status: 'error'
        });
      }
    );
  };

  const fromBookmarkHandler = e => {
    e.preventDefault();
    e.stopPropagation();

    removeFromBookmarks(
      id,
      () => {
        setArticle(prev => ({ ...prev, is_favorite: !prev.is_favorite }));
        setEvent({
          open: true,
          message: 'Новость удалена из закладок',
          status: 'success'
        });
      },
      () => {
        setEvent({
          open: true,
          message: 'Ошибка при удалении новости из закладок',
          status: 'error'
        });
      }
    );
  };

  const Share = () => (
    <YandexShare
      content={{
        title: article.title,
        url: article?.source_url
      }}
      theme={{ lang: 'en', services: 'vkontakte,facebook,telegram' }}
    />
  );

  const Source = () => (
    <CAPTION
      style={{ display: 'flex', alignItems: 'center', margin: '0 16px 0 0' }}
    >
      <img
        alt=""
        src={article.icon_url ? article.icon_url : stub_smi}
        style={{ width: '20px', height: '20px' }}
      />
      <span style={{ opacity: '.6', margin: '0 0 0 8px' }}>
        {article.source}
      </span>
    </CAPTION>
  );

  const TopBar = () => (
    <div className={styles.TopBar}>
      <div className={styles.TopItem}>
        <CAPTION style={{ opacity: '.6', margin: '0 16px 0 0' }}>
          {moment(article.date, 'YYYY-MM-DDTHH:mm:ss')
            .utc()
            .locale('ru')
            .format('DD.MM.YYYY, HH:mm МСК')}
        </CAPTION>
      </div>
      <div className={styles.TopItem}>
        <CAPTION style={{ opacity: '.6', margin: '0 16px 0 0' }}></CAPTION>
      </div>
      <div className={classNames(styles.TopItem, styles.TopItem_Source)}>
        {article.is_source_external ? (
          <a
            href={article.source_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Source />
          </a>
        ) : (
          <Source />
        )}
      </div>
      {role === 'user' && (
        <div
          className={styles.TopItem}
          onClick={e =>
            article.is_favorite ? fromBookmarkHandler(e) : toBookmarkHandler(e)
          }
          style={{ cursor: 'pointer' }}
        >
          <CAPTION
            style={{
              color: '#731982',
              margin: '0 16px 0 0',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {article.is_favorite ? <BookmarkIcon /> : <BookmarkBorderIcon />}
            {!screenIsTablet && (
              <span style={{ margin: '0 16px 0 4px' }}>
                {article.is_favorite
                  ? 'Удалить из закладок'
                  : 'Добавить в закладки'}
              </span>
            )}
          </CAPTION>
        </div>
      )}
      {countOfChildren > 1 && (
        <Link to={`/articles/group/${article?.id}`}>
          <div className={styles.TopItem} style={{ cursor: 'pointer' }}>
            <CAPTION
              style={{
                color: '#731982',
                margin: '0 16px 0 0',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <FolderIcon />
              {!screenIsTablet && (
                <span style={{ margin: '0 16px 0 4px' }}>
                  Перейти в группу этой новости
                </span>
              )}
            </CAPTION>
          </div>
        </Link>
      )}
      {article.is_source_external && (
        <>
          <Share />
          <CopyLinkButton link={article?.source_url} margin="0 20px" />
        </>
      )}
    </div>
  );

  const getBackButtonRoute = () => {
    const {
      monitoring_date,
      monitoring_type,
      monitoring_region,
      monitoring_section,
      status,
      id
    } = article;

    const formattedMonitoringDate = moment(
      monitoring_date,
      'YYYY-MM-DDTHH:mm:ss'
    ).format('YYYY-MM-DD');

    const fromBookmarks = JSON.parse(localStorage.getItem('fromBookmarks'));
    const fromArticles = JSON.parse(localStorage.getItem('fromArticles'));
    const fromGroup = JSON.parse(localStorage.getItem('fromGroup'));

    const operator =
      fromBookmarks || fromArticles || fromGroup
        ? `&operator=${monitoring_section}`
        : '';

    const isPrepared = moment(monitoring_date, 'YYYY-MM-DDTHH:mm:ss').isAfter(
      moment().locale('ru')
    );

    const region = isPrepared ? '' : `&region=${monitoring_region}`;
    const params = `?date=${formattedMonitoringDate}&type=${monitoring_type}${region}${operator}&status=${status}`;

    if (fromGroup) {
      return `/articles/group/${id}`;
    }

    if (fromArticles) {
      return `/articles/section${params}`;
    }

    if (fromBookmarks) return '/bookmarks';

    if (role === 'user') return `/${params}`;

    return `/monitoring/${isPrepared ? 'prepared' : 'released'}/${
      monitoring_type === 3 ? 'federal' : 'regional'
    }${params}`;
  };

  const renderImageOrVideoPreview = () => {
    if (article?.video_file_url || article?.video_url) {
      if (article?.video_url?.includes('youtube')) {
        return (
          <div className={styles.ContainerIframe}>
            <iframe
              width="100%"
              className={styles.Video}
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              title="news video"
              src={article.video_url}
            />
          </div>
        );
      }

      return (
        <div className={styles.ContainerIframe}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            controls
            autoPlay
            className={styles.Video}
            src={article.video_url || article.video_file_url}
          >
          </video>
        </div>
      );
    }

    if (article?.image) {
      return (
        <img alt="" src={article.image} className={styles.ArticlePreview} />
      );
    }

    return null;
  };

  return (
    <Page event={event} loading={!article}>
      <MetaTags>
        <meta property="og:type" content="article" />
        <meta property="og:title" content={article && article.title} />
        <meta property="og:url" content={window.location.href} />
      </MetaTags>
      {article && (
        <Container>
          <BackButton to={getBackButtonRoute()} />
          <TopBar />
          <Grid lg={12} xl={12}>
            <H4
              className={styles.H4}
              style={{ fontWeight: 'bold', margin: '11px 0 24px 0' }}
            >
              {article.title}
            </H4>
            {['editor', 'analyst', 'admin'].includes(role) && (
              <H6
                className={styles.H6}
                style={{
                  fontWeight: 'normal',
                  opacity: '.60',
                  margin: '24px 0 24px 0'
                }}
              >
                {article.preview}
              </H6>
            )}
            {renderImageOrVideoPreview()}
            <SUBTITLE1
              style={{
                margin: '0 0 24px 0',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                paddingTop: '24px'
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: article.text }} />
            </SUBTITLE1>
            {article.is_source_external && (
              <div className={styles.Share}>
                <Share />
                <CopyLinkButton link={article?.source_url} margin="0 20px" />
              </div>
            )}
          </Grid>
        </Container>
      )}
    </Page>
  );
};

// Exports
export default Article;
