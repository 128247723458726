// @flow
import React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '@components/DatePicker';
import SearchIcon from '@material-ui/icons/Search';
import {
  Button,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  TextField
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { StyledSubmitButton } from '../../pages/Handbook/Operators/CustomComponents';
import { ReactComponent as CellsView } from '../../assets/cells_view.svg';
import { ReactComponent as RowsView } from '../../assets/rows_view.svg';
import { VIEWS } from '@constants/views';
import { sendEmailsForUsers, sendToMonitoringDate } from '../../api/articleApi';
import { isDayDisabled } from '../../utils/isDayDisabled';
import * as classnames from 'classnames';
import { SET_EVENT } from '../../store/event/types';
import ExportReportMonitoring from '../../features/export-report-monitoring';
import styles from './styles.sass';

// Types
type Types = {
  searchHandler: string => void,
  dateFiltrationHandler: string => void,
  withoutSearch: boolean,
  day: string,
  handleClear: () => void,
  onSendToMonitoring: () => void,
  dateFilterSettings?: {
    show?: boolean,
    readonly?: boolean
  }
};

const ArticlesManipulator = ({
  searchHandler,
  dateFiltrationHandler,
  withoutSearch,
  day,
  handleClear,
  onSendToMonitoring,
  dateFilterSettings = {
    show: true,
    readonly: false
  }
}: Types) => {
  const location = useLocation();
  const view = useSelector(store => store?.global_monitoring_settings?.view);
  const dispatch = useDispatch();

  const [isOpenConfirmModal, setIsOpenConfirmModal] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(day);
  const [dateSendToMonitoring, setDSTM] = React.useState(moment());

  const screenIsSmall = useMediaQuery('(max-width:768px)');
  const screenIsMedium = useMediaQuery('(max-width:768px)');

  const handleDateChange = date => {
    if (dateFiltrationHandler) {
      // const correctDate = `${selectedDate}`.length > 10 ? moment(selectedDate).utc().format('YYYY-MM-DD') : selectedDate;
      // dateFiltrationHandler(correctDate);
      dateFiltrationHandler(
        moment(date)
          .utc()
          .format('YYYY-MM-DDT')
      );
    }

    setSelectedDate(date);
  };

  const handleSearchChange = words => {
    searchHandler(words);
  };

  const handleSearchChangeThrottle = React.useCallback(
    _.debounce(handleSearchChange, 1000),
    []
  );

  const isPrepared =
    moment()
      .add(1, 'day')
      .format('YYYY-MM-DD') === day;

  const handleSetEvent = payload =>
    dispatch({
      type: SET_EVENT,
      payload
    });

  React.useEffect(() => {
    if (document.getElementById('date-picker-inline')) {
      document.getElementById('date-picker-inline').style.cursor = 'pointer';
    }
  }, []);

  React.useEffect(() => {
    setSelectedDate(day);
  }, [day]);

  const handleChangeView = updatedView =>
    dispatch({
      type: 'UPDATE_GLOBAL_MONITORING_SETTINGS',
      payload: { view: updatedView }
    });

  return (
    <>
      <Grid
        xs={12}
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        className={styles.SearchBlock}
      >
        <Modal
          open={isOpenConfirmModal}
          onClose={() => setIsOpenConfirmModal(false)}
        >
          <Paper className={styles.Paper}>
            <div style={{ margin: '0 0 16px 0' }}>
              Отправить письма с мониторингом?
            </div>
            <Grid container xl={12} lg={12} justify="space-between">
              <Button size="large" onClick={() => setIsOpenConfirmModal(false)}>
                Отменить
              </Button>
              <StyledSubmitButton
                variant="contained"
                size="large"
                onClick={() => {
                  setIsOpenConfirmModal(false);
                  sendEmailsForUsers(location.pathname.split('/')[3], res => {
                    handleSetEvent({
                      open: true,
                      message: res.message,
                      status: res.status
                    });
                  });
                }}
              >
                Отправить
              </StyledSubmitButton>
            </Grid>
          </Paper>
        </Modal>
        <Grid xs={12} sm={5} className={styles.SearchBlock__Grid}>
          {!withoutSearch && (
            <TextField
              fullWidth
              placeholder="Поиск"
              style={{
                width: '100%'
              }}
              variant="outlined"
              onChange={e => handleSearchChangeThrottle(e.target.value)}
              icon
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              size="small"
            />
          )}
        </Grid>
        <Grid
          xs={12}
          sm={dateFilterSettings.show ? 6 : 2}
          lg={dateFilterSettings.show ? 6 : 2}
          direction="row"
          justify={screenIsSmall ? 'space-between' : 'flex-end'}
          alignItems="center"
          container
        >
          {dateFilterSettings.show && (
            <div
              className={classnames(
                styles.PublicDate,
                dateFilterSettings.readonly && styles.PublicDate_readonly
              )}
            >
              {/*  eslint-disable-next-line jsx-a11y/no-static-element-interactions  */}
              <div className={styles.PublicDate__container}>
                <div>
                  <span>Дата публикации:</span>
                </div>
                <div className={styles.PublicDate__datepicker}>
                  {dateFilterSettings.readonly ? (
                    <span className={styles.PublicDate__date}>
                      {moment(selectedDate).format('DD.MM.YYYY')}
                    </span>
                  ) : (
                    <DatePicker
                      format="dd.MM.yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                      maxDate={moment()
                        .utc()
                        .format('YYYY-MM-DD')}
                      shouldDisableDate={isDayDisabled}
                    />
                  )}
                </div>
                <ExportReportMonitoring />
              </div>
            </div>
          )}
          {!screenIsMedium && (
            <div className={styles.SelectView}>
              {/*  eslint-disable-next-line jsx-a11y/no-static-element-interactions  */}
              <div
                onClick={() => handleChangeView(VIEWS.cells)}
                className={styles.SelectView__control}
              >
                <CellsView
                  className={`${styles.SelectView__image} ${
                    view === VIEWS.cells ? styles.SelectView__image_active : ''
                  }`}
                />
              </div>
              {/*  eslint-disable-next-line jsx-a11y/no-static-element-interactions  */}
              <div
                onClick={() => handleChangeView(VIEWS.rows)}
                className={styles.SelectView__control}
              >
                <RowsView
                  className={`${styles.SelectView__image} ${
                    view === VIEWS.rows ? styles.SelectView__image_active : ''
                  }`}
                />
              </div>
            </div>
          )}
        </Grid>
      </Grid>
      <>
        {isPrepared && location.pathname.split('/')[2] === 'prepared' && (
          <div style={{ display: 'flex' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 10 }}>Дата мониторинга:</span>
              <DatePicker
                format="dd.MM.yyyy"
                value={dateSendToMonitoring}
                onChange={date => setDSTM(date)}
                minDate={moment()
                  .utc()
                  .format('YYYY-MM-DD')}
                shouldDisableDate={isDayDisabled}
              />
            </span>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginLeft: 50 }}
              onClick={() => {
                if (dateSendToMonitoring) {
                  onSendToMonitoring(true);
                  sendToMonitoringDate(
                    location.pathname.split('/')[3] === 'federal' ? 3 : 2,
                    moment(dateSendToMonitoring).format('YYYY-MM-DD'),
                    res => {
                      handleSetEvent({
                        open: true,
                        message: `Новости в мониторинг и письма отправлены`,
                        status: res?.status
                      });
                      onSendToMonitoring('done');
                    },
                    () => {
                      handleSetEvent({
                        open: true,
                        message: `Новости в мониторинг и письма отправлены`,
                        status: 'error'
                      });
                      onSendToMonitoring('done');
                    }
                  );
                  handleClear();
                } else {
                  handleSetEvent({
                    open: true,
                    message: `Установите дату для отправки новостей в мониторинг`,
                    status: 'error'
                  });
                }
              }}
            >
              Поместить в мониторинг
            </Button>
          </div>
        )}
      </>
    </>
  );
};

export default ArticlesManipulator;
